<template>
  <div>
    <div class="header-navbar-top-end-social-link" v-if="isHeader">
      <div class="d-flex justify-content-between header-scoial-link">
        <a
          v-if="site_info.edc_social.facebook"
          aria-label="follow us in facebook"
          :href="site_info.edc_social.facebook"
          class="text-white mx-2"
          ><i class="fab fa-facebook-f"></i
        ></a>
        <a
          v-if="site_info.edc_social.twitter"
          :href="site_info.edc_social.twitter"
          class="text-white mx-1"
          aria-label="follow us in twitter"
          target="_blank"
          ><i class="fab fa-twitter"></i
        ></a>
        <a
          v-if="site_info.edc_social.youtube"
          :href="site_info.edc_social.youtube"
          class="text-white mx-1"
          aria-label="follow us in youtube"
          target="_blank"
          ><i class="fab fa-youtube"></i
        ></a>
        <a
          v-if="site_info.edc_social.linkedin"
          :href="site_info.edc_social.linkedin"
          aria-label="follow us in linkedin"
          class="text-white mx-1"
          target="_blank"
          ><i class="fab fa-linkedin"></i
        ></a>
        <a
          v-if="site_info.edc_social.instagram"
          :href="site_info.edc_social.instagram"
          aria-label="follow us in instagram"
          class="text-white mx-1"
          target="_blank"
          ><i class="fab fa-instagram"></i
        ></a>
        <a
          v-if="site_info.edc_social.soundcloud"
          :href="site_info.edc_social.soundcloud"
          aria-label="follow us in soundcloud"
          class="text-white mx-1"
          target="_blank"
          ><i class="fab fa-soundcloud"></i
        ></a>
      </div>
    </div>
    <div
      class="d-flex justify-content-between footer-scoial-link"
      v-if="!isHeader"
    >
      <a
        v-if="site_info.edc_social.facebook"
        :href="site_info.edc_social.facebook"
        aria-label="follow us in facebook"
        class="text-white"
        target="_blank"
        ><i class="fab fa-facebook-f"></i
      ></a>
      <a
        v-if="site_info.edc_social.twitter"
        :href="site_info.edc_social.twitter"
        aria-label="follow us in twitter"
        class="text-white"
        target="_blank"
        ><i class="fab fa-twitter"></i
      ></a>
      <a
        v-if="site_info.edc_social.youtube"
        :href="site_info.edc_social.youtube"
        aria-label="follow us in youtube"
        class="text-white"
        target="_blank"
        ><i class="fab fa-youtube"></i
      ></a>
      <a
        v-if="site_info.edc_social.linkedin"
        :href="site_info.edc_social.linkedin"
        aria-label="follow us in linkedin"
        class="text-white"
        target="_blank"
        ><i class="fab fa-linkedin"></i
      ></a>
      <a
        v-if="site_info.edc_social.instagram"
        :href="site_info.edc_social.instagram"
        aria-label="follow us in instagram"
        class="text-white"
        target="_blank"
        ><i class="fab fa-instagram"></i
      ></a>
      <a
        v-if="site_info.edc_social.soundcloud"
        :href="site_info.edc_social.soundcloud"
        aria-label="follow us in soundcloud"
        class="text-white"
        target="_blank"
        ><i class="fab fa-soundcloud"></i
      ></a>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "SocialMedia",
  props: {
    isHeader: { type: Boolean, default: true },
  },

  computed: {
    ...mapGetters("site", ["site_info"]),
  },
  methods: {
    //...mapActions("site", ["getSiteInfo"]),
  },
  created() {
    //this.getSiteInfo();
  },
};
</script>