import axios from "axios";
export default {
	namespaced: true,
	state: () => ({
		testimonials: [],
		total: 0,
		isEmptyData: false,
		isShowLoading: true,
	}),

	getters: {
		testimonials: (state) => {
			return state.testimonials;
		},
		total: (state) => {
			return state.total;
		},
		isEmptyData: (state) => {
			return state.isEmptyData;
		},
		isShowLoading: (state) => {
			return state.isShowLoading;
		},
	},
	mutations: {
		SET_TESTIMONIAL(state, payload) {
			payload.forEach((element) => {
				state.testimonials.push(element);
			});
		},

		REST_TESTIMONIAL(state, payload) {
			state.testimonials = payload;
		},

		isEmptyData(state, payload) {
			state.isEmptyData = payload;
		},
		SET_TOTAL(state, payload) {
			state.total = payload;
		},
		isShowLoading(state, payload) {
			state.isShowLoading = payload;
		},
	},
	actions: {
		async getTestimonials({ commit }, payload) {
			commit("isShowLoading", true);
			await axios
				.get("/testimonials", { params: payload })
				.then((response) => {
					if (response.status === 200) {
						commit("SET_TOTAL", response.data.total);
						commit("SET_TESTIMONIAL", response.data.testimonials);
						if (response.data.testimonials.length <= 0) {
							commit("isEmptyData", true);
						} else {
							commit("isEmptyData", false);
						}
					}
				})
				.catch((error) => {
					commit("isEmptyData", true);
				})
				.finally(() => {
					commit("isShowLoading", false);
				});
		},

		resetTestimonials({ commit }, payload) {
			commit("REST_TESTIMONIAL", payload);
		},
	},
};
