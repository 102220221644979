import axios from "axios";
export default {
	namespaced: true,
	state: {
		status: {},
	},
	getters: {
		status: (state) => {
			return state.status;
		},
	},
	mutations: {
		SET_STATUS(state, payload) {
			state.status = payload;
		},
	},
	actions: {
		async getStatusValue({ commit }, payload) {
			await axios
				.get("/project-status")
				.then((response) => {
					if (response.status === 200) {
						commit("SET_STATUS", response.data);
					}
				})
				.catch((error) => {});
		},
	},
};
