<template>
  <div>
    <img
      v-if="user.user_avatar == ''"
      src="@/assets/images/avatar.png"
      :width="size"
      class="rounded-circle border border-3 dropdown-toggle"
      type="button"
      id="dropdownUserArea"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      :alt="user.display_name"
    />
    <img
      v-else
      :src="user.user_avatar"
      :width="size"
      class="rounded-circle border border-3 dropdown-toggle"
      type="button"
      id="dropdownUserArea"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      :alt="user.display_name"
    />
    <span v-if="isName" class="edc-color-secondary">
      {{ user.display_name }}
    </span>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "UserAvatar",
  props: {
    size: {
      type: [String, Number],
      default: 100,
    },
    isName: false,
  },
  computed: {
    ...mapState("auth", ["user", "isLogin"]),
  },
  methods: {
    //...mapActions("auth", ["getUser"]),
  },
};
</script>

<style lang="scss" scoped>
</style>