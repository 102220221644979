<template>
  <div class="page-head edc-bg-primary" v-if="$route.fullPath != '/'">
    <div class="container">
      <div
        class="
          d-flex
          flex-wrap
          justify-content-between
          align-items-center
          py-2 py-lg-5
        "
      >
        <h1 class="page-head-title mb-3 mb-lg-0">{{ headTitle }}</h1>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-2 mb-lg-0">
            <li class="breadcrumb-item">
              <router-link to="/">الرئيسية</router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ headTitle }}
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PageHead",
  data() {
    return {
      headTitle: this.$route.name,
    };
  },
  props: {},
  mounted() {
    //console.log("mounted", this.$route);
    this.headTitle = this.$t("title." + this.$route.meta.title);
  },
  created() {
    //console.log("created", this.$route);
  },
  updated() {
    //console.log("updated", this.$route);
  },
  watch: {
    "$route.fullPath"(newPath, oldId) {
      this.headTitle = this.$t("title." + this.$route.meta.title);
    },
  },
};
</script>

