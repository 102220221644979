import axios from "axios";

export default {
	namespaced: true,
	state: () => ({
		count: 0,
		list: {},
		lists: [],
		isLoadMore: true,
		isLoading: true,
	}),

	getters: {
		count: (state) => {
			return state.count;
		},
		list: (state) => {
			return state.list;
		},
		lists: (state) => {
			return state.lists;
		},
		isLoadMore: (state) => {
			return state.isLoadMore;
		},
		isLoading: (state) => {
			return state.isLoading;
		},
	},
	mutations: {
		SET_COUNT(state, payload) {
			state.count = payload;
		},
		SET_LIST(state, payload) {
			state.list = payload;
		},
		SET_LISTS(state, payload) {
			payload.forEach((element) => {
				state.lists.push(element);
			});
		},
		SET_IS_LOAD_MORE(state, payload) {
			state.isLoadMore = payload;
		},
		SET_IS_LOADING(state, payload) {
			state.isLoading = payload;
		},
	},
	actions: {
		async getCount({ commit }, payload) {
			commit("SET_IS_LOADING", true);
			await axios
				.get("/get-notify-count")
				.then((response) => {
					if (response.status === 200) {
						commit("SET_COUNT", response.data);
					}
				})
				.catch((error) => {})
				.finally(() => {
					commit("SET_IS_LOADING", false);
				});
		},
		async getList({ commit, rootState }, payload) {
			commit("SET_IS_LOADING", true);
			if (rootState.auth.isLogin === true) {
				await axios
					.get("/get-notifications", { params: payload })
					.then((response) => {
						if (response.status === 200) {
							commit("SET_LIST", response.data.list);
							commit("SET_COUNT", response.data.count);
						}
					})
					.catch((error) => {
						commit("SET_LIST", {});
						commit("SET_COUNT", 0);
					})
					.finally(() => {
						commit("SET_IS_LOADING", false);
					});
			} else {
				commit("SET_LIST", {});
				commit("SET_COUNT", 0);
				commit("SET_IS_LOADING", false);
			}
		},
		async getPaginateList({ commit }, payload) {
			commit("SET_IS_LOADING", true);
			await axios
				.get("/get-all-notifications", { params: payload })
				.then((response) => {
					if (response.status === 200) {
						commit("SET_LISTS", response.data.list);
					}
				})
				.catch((error) => {
					if (error.response.status === 400) {
						commit("SET_IS_LOAD_MORE", false);
					}
				})
				.finally(() => {
					commit("SET_IS_LOADING", false);
				});
		},
		async setNotfiyRead({ commit }, payload) {
			commit("SET_IS_LOADING", true);
			await axios
				.post("/set-all-read", { params: payload })
				.then((response) => {
					if (response.status === 200) {
						commit("SET_COUNT", 0);
					}
				})
				.catch((error) => {})
				.finally(() => {
					commit("SET_IS_LOADING", false);
				});
		},
		resetNotify({commit}){
			commit("SET_LIST", {});
			commit("SET_COUNT", 0);
			commit("SET_IS_LOADING", false);
		}
	},
};
