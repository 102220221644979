import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store";
import app_config from "./config";
import messages from "./lang/translation";
import axios from "axios";
import VueI18n from "vue-i18n";
import VueSwal from "vue-swal";
import CountUp from "@/components/CountUp.vue";
import Meta from "vue-meta";
import AOS from "aos";
import auth from "./auth";
import DToaster from "dtoaster";
import Modal from "@burhanahmeed/vue-modal-2";
import VueSocialSharing from 'vue-social-sharing'


import "aos/dist/aos.css";
import "dtoaster/dist/dtoaster.css";
require("bootstrap");
Vue.use(DToaster, {
	position: "top-left", //toasts container position on the screen
	containerOffset: "45px", //toasts container offset from top/bottom of the screen
});
Vue.use(VueSocialSharing);
Vue.use(VueSwal);
Vue.use(VueI18n);
Vue.config.productionTip = false;
Vue.use(AOS.init());
Vue.use(Meta, {
	refreshOnceOnNavigation: true,
});
Vue.use(Modal);

router.beforeEach((to, from, next) => {
	window.scrollTo(0, 0);
	store.dispatch("auth/getAuthToken");
	store.dispatch("site/getSiteInfo");

	if (to.meta.guest === true && auth !== null && auth.isLogin() === true) {
		if (from.name !== "home") {
			router.push({ name: "home" });
		}
	} else if (to.meta.auth === true && auth !== null &&  auth.isLogin() === false) {
		if (from.name !== "login") {
			router.push({ name: "login" });
		}
	} else {
		next();
	}
});
store.subscribe((moutation) => {
	switch (moutation.type) {
		case "auth/SET_TOKEN": {

			let auth = JSON.parse(moutation.payload);
			if (
				moutation.payload != null &&
				(auth !== undefined || true) &&
				(auth.api_token !== "" || auth.api_token !== null)
			) {
				axios.defaults.headers.common["Authorization"] = `${auth.api_token}`;
			} else {
				axios.defaults.headers.common["Authorization"] = "";
			}
		}
	}
	//console.log(moutation);
});

axios.defaults.baseURL = app_config.API_URL;
axios.mode = "no-cors";
Vue.config.performance = process.env.NODE_ENV !== "production";

const i18n = new VueI18n({
	locale: "ar", // set locale
	fallbackLocale: "ar",
	messages, // set locale messages
});
Vue.component("count-up", CountUp);
new Vue({
	router,
	i18n,
	store,
	render: (h) => h(App),
}).$mount("#app");
