<template>
  <div class="parent-pre-loading">
    <div class="pre-loading">
      <div v-if="isShowLoading" class="loader edc-color-red">Loading...</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "loading",
  props: {
    isShowLoading: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style lang="css" scoped>
.pre-loading {
  /* Center vertically and horizontally */
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px; /* Apply negative top and left margins to truly center the element */
    z-index: 999999;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>