import axios from "axios";
export default {
	namespaced: true,
	state: () => ({
        services: [],
        service: {},
	}),

	getters: {
		services: (state) => {
			return state.services;
		},
        service: (state) => {
			return state.service;
		},
	},
	mutations: {
		SET_SERVICES(state, payload) {
			state.services = payload;
        },
        SET_SERVICE(state, payload) {
			state.service = payload;
		},
	},
	actions: {
		async getServices({ commit }, payload) {
			await axios
				.get("/services")
				.then((response) => {
					if (response.status === 200) {
						commit("SET_SERVICES", response.data);
					}
				})
				.catch((error) => {});
        },
		async getService({ commit }, payload) {
	
			await axios
                .get("/service", {
                    params: {
                        id: payload
                    }
                })
				.then((response) => {
					if (response.status === 200) {
						commit("SET_SERVICE", response.data);
					}
				})
				.catch((error) => {});
		},
	},
};
