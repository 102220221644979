import axios from "axios";

export default {
	namespaced: true,
	state: () => ({
		reports: [],
		total: 0,
		isShowLoadMoreButton: false,
		isEmptyData: false,
		isShowLoading: true,
	}),

	getters: {
		reports: (state) => {
			return state.reports;
		},
		total: (state) => {
			return state.total;
		},
		isEmptyData: (state) => {
			return state.isEmptyData;
		},
		isShowLoading: (state) => {
			return state.isShowLoading;
		},
		isShowLoadMoreButton: (state) => {
			return state.isShowLoadMoreButton;
		},
	},
	mutations: {
		SET_REPORTS(state, payload) {
			payload.forEach((element) => {
				state.reports.push(element);
			});
		},
		SET_TOTAL(state, payload) {
			state.total = payload;
		},
		REST_REPORTS(state, payload) {
			state.reports = payload;
		},
		isEmptyData(state, payload) {
			state.isEmptyData = payload;
		},
		isShowLoading(state, payload) {
			state.isShowLoading = payload;
		},
		SET_IS_LOAD_MORE(state, payload) {
			state.isShowLoadMoreButton = payload;
		},
	},
	actions: {
		async getReports({ commit }, payload) {
			await axios
				.get("/reports", { params: payload })
				.then((response) => {
					if (response.status === 200) {
						//console.log("response.data", response.data);
						commit("SET_REPORTS", response.data.reports);
						commit("SET_TOTAL", response.data.total);

						commit("SET_IS_LOAD_MORE", true);
					} else if (response.status === 400) {
						commit("SET_IS_LOAD_MORE", false);
					}
				})
				.catch((error) => {
					commit("isEmptyData", true);
				})
				.finally(() => {
					commit("isShowLoading", false);
				});
		},

		resetReports({ commit }, payload) {
			commit("REST_REPORTS", payload);
		},
	},
};
