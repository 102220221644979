const messages = {
	ar: {
		app: {
			name: "الدعوة الإلكترونية",
		},
		projects: {
			muslin_library: "المكتبة",
			sabeeli_academy: "أكاديمية سبيلي",
			edc_waqf: "الوقف الدعوي",
			quran_radios: "القرآن الكريم",
			chat_on_faith: "حوار الإيمان",
		},
		message: {
			all_fields_is_requierd: "يرجي ملئ جميع الحقول",
			all_correct_email: "يرجي ادخال بريد الكتروني صحيح",
			loading: "جاري التحميل فضلاً انتظر قليلاً ....",
			hello: "اهلا",
			must_insert_ammount: "يرجي ادخال مبلغ التبرع",
			are_you_sure: "هل انت متاكد من هذا الاجراء",
			will_delete_project: "سيتم حذف هذا المشروع",
			yes_delete: "نعم,احذف",
			redo: "تراجع",
			success: "تمت عملية الحذف بنجاح",
			no_any_payment_found: "لايوجد اي تبرعات",
			donate_success: "تمت عملية التبرع بنجاح",
			donate_fail: "حدث خطا فشلت عملية التبرع",
			donate_again: "تبرع مرة اخري",
			allow_connect: "كن علي  تواصل معنا",
			error404: "عذراً، هناك خطأ ما!",
			login_message: "سجل دخولك لتكون عل يتواصل",
			register_message: "سجل معنا لمتابعة تبرعاتك",
			not_project_found: "عذرا لم يتم العثور علي اي مشاريع",
			not_post_found: "عذرا لم يتم العثور علي اي مقالات",
			not_report_found: "عذرا لم يتم العثور علي اي تقارير",
			update_success: "تم التعديل بنجاح",
			update_fail: "حدث خطا لم يتم التعديل",
			delete_success: "تم الحذف بنجاح",
			delete_fail: "حدث خطا لم يتم الحذف",
			change_password_fail: "حدث خطا لم يتم تعدي الباسورد",
			no_any_payment_is_active: "لاتوجد طريقة دفع مفعلة",
			new_un_read_notify: "لديك عدد {notify_count} اشعار جديدة غير مقرؤء",
			not_notify_found: "لاتوجد اشعارات حاليا",
			not_testimonial_found: "لاتوجد بيانات",
			add_to_cart_success: "تم اضافة المشروع للسلة بنجاح",
			add_to_cart_fail: "حدث خطا لم يتم اضافة المشروع للسلة",
			empty_cart_success: "تم تفريغ السلة بنجاح",
		},
		input_label: {
			name: "الاسم",
			email: "البريد الإلكتروني",
			message: "الرسالة",
		},
		gift_input: {
			name: "اسمك",
			email: "بريدك",
			to_name: "اسم المهدى إليه ",
			to_email: "بريد  المهدى إليه",
		},
		label: {
			name: "الاسم",
			page_count: "عدد الصفحات",
			amount: "المبلغ",
			donate: "بواسطة",
			payment_id: "معرف التبرع",
			created_at: "بتاريخ",
			track_id: "رقم التتبع",
			transaction_id: "رقم التحويل",
			reference_id: "رقم مرجعي",
			your_donate: "قيمة تبرعك ",
			total: "اجمالي المبلغ",
			project_value: "قيمة المشروع",
			amount_collected: "المحصل",
			remaining_amount: "المتبقي",
			project_is_complited: "تم اكتمال المشروع",
			edit_amount: "تعديل مبلغ التبرع",
			add_amount: "أدخل مبلغ التبرع",
			my_donations: "تبرعاتي",
			my_following: "قائمة متبعاتي",
			profile: "صفحتي الشخصية",
			change_password: "تغيير كلمة السر",
			logout: "خروج",
			download_report: "تحميل التقرير",

			your_total_donate: "تبرعت بـ",
			show_log: "معاينة تبرعاتك",
			total_cart: "الإجمالي",
			payment_details: "بيانات التبرع",
			donate_status: "الحالة",
			donate_gateway: "بواسطة",
			Benefactor: "فاعل خير",
			NewDonner: "متبرع جديد",
			Login: "متبرع سابق",
			email: "البريد الإلكتروني",
			mobile: "الموبايل",
			username: "اسم الدخول",
			password: "كلمة السر",
			repeat_password: "اعادة كلمة المرور",
			first_name: "الاسم الاول",
			last_name: "الاسم الثاني",
			register: "تسجيل",
			email_or_username: "البريد او اسم الدخول",
			keywords: "كلمة البحث .. ",
			personal_info: "بيانات شخصية",
			user_info: "بيانات العضوية",
			wellcome: "مرحبا ",
			old_password: "كلمة المرور القديمة",
			new_password: "كلمة المرور الجديدة",
			comment: "تعليق",
			comment_name: "اسمك",
			comment_email: "البريد الإلكتروني",
			comment_text: "التعليق",
			supported_payment_methods: "طرق الدفع المعتمدة",
			fast_list: "روابط سريعة",
			site_map: "خريطة الموقع",
			contact_with_us: "تواصل معنا",
			address: "العنوان",
			footer_mobile: "الهاتف",
			call_center: "مركز الاتصال",
			agetnt_number: "رقم المندوب",
			donate_to_project: "للتبرع للمشروع",
			certificat: "احصل على الشهادة",
		},
		btn: {
			subscribe_reporting_service: "اشترك في خدمة التقارير",
			print: "طباعة",
			send_message: "أرسل رسالة",
			call_representative: "اطلب مندوب",
			donate_now: "تبرع الان",
			login: "تسجيل الدخول",
			register: "متبرع جديد",
			zakat_is_permissible: "تجوز الزكاة",
			load_more: "عرض المزيد",
			follow: "متابعة",
			un_follow: "الغاء المتابعة",
			donated: "تم التبرع",
			add_to_cart: "اضف إلى السلة",
			fast_donate: "تبرع سريع",
			show_cart: "عرض السلة",
			finish_donate: " إنهاء التبرع",
			empty_cart: "تفريغ السلة",
			cart_is_empty: "السلة فارغة",
			update_project: "تحديث المشروع",
			delete_project: "حذف المشروع",
			home: "الرئيسية",
			show_project: " تصفح  المشاريع",
			DonateNow: "تبرع الآن",
			download: "تحميل",
			forget_password: "نسيت كلمة المرور ؟",
			update_profile: "تعديل",
			update: "تعديل",
			read_more: "اقرا المزيد",
			read_less: "أقل ...",
			for_more: "للمزيد ...",
			add_comment: "أضف تعليق",
			show_all: "عرض الكل",
			pay_now: "توجة إلى بوابة الدفع",
			send_gift: "تبرع وارسل الهدية",
		},
		title: {
			undefined: "",
			our_servcies : "خدماتنا",
			testimonial: "قالو عنا",
			contact_us: "اتصل بنا",
			send_to_us: "راسلنا",
			contact_with_us: "تواصل معنا",
			display: "حدد طريقة العرض",
			our_project: "مشاريعنا",
			my_donate: "تبرعاتي",
			projects: "المشاريع",
			choose_payment_type: "قم باختيار طريقة الدفع",
			home: "الرئيسية",
			login: "تسجيل دخول",
			register_new_donor: "تسجيل متبرع جديد",
			change_profile_details: "تعديل بياناتي الشخصية",
			change_password: "تعديل كلمة المرور",
			wishlist: "متابعة المشاريع",
			profile: "صفحتي الشخصية",
			about: "اتصل بنا",
			quran_radios: "القرآن الكريم",
			fullCart: "سلة التبرع",
			muslim_library: "المكتبة الاسلامية",
			chat_on_faith: "حوار الإيمان",
			appname: "الدعوة الإلكترونية",
			not_found: "صفحة غير موجودة",
			result: "نتيجة تبرعك",
			media_center: "المركز الأعلامي",
			news: "الأخبار",
			reports: "التقارير",
			show_news: "معاينة خبر",
			add_comment_title: "أضف تعليق",
			view_comments: " ابرز التعليقات",
			share_post: "  مشاركة الخبر",
			show_taxonamy: "تصفيه",
			last_project: "اخر المشاريع",
			view_product: "معاينة مشروع",
			new_ntify_com: "لديك اشعارات جديدة",
			show_notifications: "الإشعارات",
			testimonial_quran: "توصيات الجمهور",
			testimonial_library: "توصيات الجمهور",
			chat_tool: "ادوات الحوار",
			sabeeli_academy: "أكاديمية سبيلي",
			edc_waqf: "الوقف الدعوي",
		},
		elments: {
			attr: "",
		},
	},
	en: {
		message: {
			hello: "hello world",
		},
	},
	ja: {
		message: {
			hello: "こんにちは、世界",
		},
	},
};
export default messages;
