import axios from "axios";
import userAuth from "../../auth";
export default {
	namespaced: true,
	state: {
		auth: {},
		user: {},
		isLogin: false,
	},
	getters: {
		auth: (state) => {
			return state.auth;
		},
		user: (state) => {
			return state.user;
		},
		isLogin: (state) => {
			return state.isLogin;
		},
	},
	mutations: {
		SET_TOKEN(state, payload) {
			state.auth = payload;
		},
		SET_ISLOGIN(state, payload) {
			state.isLogin = payload;
		},
		UPDATE_USER(state, payload) {
			state.user = payload;
		},
	},
	actions: {
		getAuthToken({ commit, dispatch }, payload) {
			payload = localStorage.getItem("edc_auth");
			commit("SET_TOKEN", payload);
			dispatch("getUser");
		},
		async updateProfile({ commit }, payload) {
			return await axios.post("/update-profile", payload);
		},
		async getUser({ commit }, payload) {
			await axios
				.post("/user", payload)
				.then((response) => {
					//console.log(response);
					if (response.status === 200) {
						commit("UPDATE_USER", response.data);
						commit("SET_ISLOGIN", true);
					} else {
						commit("UPDATE_USER", {});
						commit("SET_ISLOGIN", false);
					}
				})
				.catch((error) => {});
		},
		async changePassword({ commit }, payload) {
			return await axios.post("/change-password", payload);
		},
		async register({ commit }, payload) {
			return await axios.post("/register", payload);
		},
		async login({ commit }, payload) {
			return await axios.post("/login", payload);
		},
		logOut({ commit }) {
			localStorage.removeItem(userAuth.authSaveKey);
			commit("UPDATE_USER", null);
			commit("SET_ISLOGIN", false);

		},
	},
};
