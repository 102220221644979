<template>
  <div v-if="site_info.payment" class="align-content-center">
    <img
        v-if="site_info.payment.knet === 'active'"
        data-aos="zoom-in"
        src="@/assets/images/knet.png"
        width="26"
        height="20"
        class="bg-white m-1"
        alt="KNET"
        srcset="@/assets/images/knet.png"
    />
    <img
        v-if="site_info.payment.visa === 'active'"
        src="@/assets/images/visa.png"
        data-aos="zoom-in"
        class="bg-white m-1"
        height="20"
        width="67"
        alt="VISA"
        srcset="@/assets/images/visa.png"
    />
    <div
        v-if="site_info.myfatoorah_payment.length > 0 && site_info.payment.myfatoorah === 'active'"
        
    />
      <div v-for="mf_payment in site_info.myfatoorah_payment">
        <img
                
                :src="mf_payment.image"
                data-aos="zoom-in"
                class="bg-white m-1"
                height="auto"
                width="40"
                :alt="mf_payment.title"
                :title="mf_payment.title"
                :srcset="mf_payment.image"
        />
        <span class="fs-9" data-aos="zoom-in">{{ mf_payment.title }}</span>
      </div>
 
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "FooterPaymentList",
  computed: {
    ...mapGetters("site", ["site_info"]),
  },
  methods: {
    ...mapActions("site", ["getSiteInfo"]),
  },
  created() {
    if(this.site_info.payment === undefined || this.site_info.payment === ""){
      this.getSiteInfo();
    }
  }
}
</script>

<style scoped>

</style>