import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "home",
		meta: {
			title: "appname",
		},
		component: () =>
			import(/* webpackChunkName: "home" */ "../views/HomePage.vue"),
	},
	{
		path: "/chat",
		meta: {
			title: "chat",
		},
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/Chat.vue"),
	},
	{
		path: "/about",
		meta: {
			title: "about",
		},
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/AboutusPage.vue"),
	},
	{
		path: "/projects",
		meta: {
			title: "our_project",
		},
		component: () =>
			import(/* webpackChunkName: "projects" */ "../views/ProjectsPage.vue"),
	},
	{
		path: "/products/:slug",
		meta: {
			title: "view_product",
		},
		component: () =>
			import(/* webpackChunkName: "my-donate" */ "../views/ProjectPage.vue"),
		params: { slug: String },
		query: { mk: "" },
	},
{
	path: "/services/:slug",
	name: 'services',
		meta: {
			title: "our_servcies",
		},
		component: () =>
			import(/* webpackChunkName: "my-donate" */ "../views/ServciePage.vue"),
		params: { slug: String },
	},
	{
		path: "/profile",
		meta: {
			title: "profile",
			auth: true,
		},
		component: () =>
			import(
				/* webpackChunkName: "my-donate" */ "../views/Auth/ProfilePage.vue"
			),
	},
	{
		path: "/change-password",
		name: "/change-password",
		meta: {
			title: "change_password",
			auth: true,
		},
		component: () =>
			import(
				/* webpackChunkName: "my-donate" */ "../views/Auth/ChangePasswordPage.vue"
			),
	},

	{
		path: "/user-login",
		name: "login",
		meta: {
			title: "login",
			guest: true,
		},
		component: () =>
			import(/* webpackChunkName: "login" */ "../views/Auth/LoginPage.vue"),
	},
	{
		path: "/user-register",
		meta: {
			title: "register_new_donor",
			guest: true,
		},
		component: () =>
			import(/* webpackChunkName: "login" */ "../views/Auth/RegisterPage.vue"),
	},

	{
		path: "/wishlist",
		meta: {
			title: "wishlist",
			auth: true,
		},
		component: () =>
			import(
				/* webpackChunkName: "Wishlist" */ "../views/Profile/WishlistPage.vue"
			),
	},
	{
		path: "/mydonate",
		name: "my_donate",
		meta: {
			title: "my_donate",
			auth: true,
		},
		component: () =>
			import(
				/* webpackChunkName: "my-donate" */ "../views/Profile/MyDonatePage.vue"
			),
	},

	{
		path: "/result",
		meta: {
			title: "result",
		},
		component: () =>
			import(/* webpackChunkName: "Result" */ "../views/ResultPage.vue"),
	},

	{
		path: "/cart",
		meta: {
			title: "fullCart",
		},
		component: () =>
			import(/* webpackChunkName: "cart" */ "../views/CartPage.vue"),
	},
	{
		path: "/chat-on-faith",
		meta: {
			title: "chat_on_faith",
		},
		component: () =>
			import(
				/* webpackChunkName: "chat-on-faith" */ "../views/Projects/ChatOnFaith.vue"
			),
	},
	{
		path: "/quran-radios",
		meta: {
			title: "quran_radios",
		},
		component: () =>
			import(
				/* webpackChunkName: "quran-radios" */ "../views/Projects/QuranRadios.vue"
			),
	},
	{
		path: "/muslim-library",
		meta: {
			title: "muslim_library",
		},
		component: () =>
			import(
				/* webpackChunkName: "muslim-library" */ "../views/Projects/MuslimLibrary.vue"
			),
	},
	{
		path: "/sabeeli-academy",
		meta: {
			title: "sabeeli_academy",
		},
		component: () =>
			import(
				/* webpackChunkName: "quran-radios" */ "../views/Projects/SabeeliAcademy.vue"
			),
	},
	{
		path: "/edc-waqf",
		meta: {
			title: "edc_waqf",
		},
		component: () =>
			import(
				/* webpackChunkName: "quran-radios" */ "../views/Projects/Waqf.vue"
			),
	},
	{
		path: "/news",
		meta: {
			title: "news",
		},
		component: () =>
			import(
				/* webpackChunkName: "muslim-library" */ "../views/Post/PostsPage.vue"
			),
	},

	{
		path: "/news/show/:slug",
		meta: {
			title: "show_news",
		},
		params: { slug: String },
		component: () =>
			import(
				/* webpackChunkName: "muslim-library" */ "../views/Post/ShowPostPage.vue"
			),
	},
	{
		path: "/news/:filter/:slug",
		meta: {
			title: "show_taxonamy",
		},
		params: { filter: String, slug: String },
		component: () =>
			import(
				/* webpackChunkName: "muslim-library" */ "../views/Post/PostsPage.vue"
			),
	},
	{
		path: "/reports",
		meta: {
			title: "reports",
		},
		component: () =>
			import(
				/* webpackChunkName: "muslim-library" */ "../views/Reports/ReportsPage.vue"
				),
	},
	{
		path: "/notifications",
		meta: {
			title: "show_notifications",
		},
		params: {},
		component: () =>
			import(
				/* webpackChunkName: "muslim-library" */ "../views/NotificationsPage.vue"
			),
	},
	{
		path: "/404",
		meta: {
			title: "not_found",
		},
		component: () =>
			import(/* webpackChunkName: "404" */ "../views/NotFound.vue"),
	},
	{
		path: "/:shortUrl",
		name: "shortlink",
		params: { shortUrl: String },
		meta: {
			title: "appname",
		},
		component: () =>
			import(/* webpackChunkName: "home" */ "../views/HomePage.vue"),
	},
	{
		path: "*",
		meta: {
			title: "not_found",
		},
		component: () =>
			import(/* webpackChunkName: "404" */ "../views/NotFound.vue"),
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
