<template>
  <header>
    <via-najat-component></via-najat-component>
    <div class="header-navbar-top d-none d-sm-block">
      <div class="container">
        <div class="d-lg-flex justify-content-between align-items-center py-2">
          <div class="header-navbar-top-start">
            <div class="d-flex justify-content-between">
              <div class="mx-2 d-flex align-items-center">
                <i class="fas fa-phone fa-lg mx-2 text-muted"></i>
                <a
                  :href="`tel:+${site_info.contact.agent_mobile}`"
                  dir="ltr"
                  class="d-inline"
                  >{{ site_info.contact.agent_mobile }}</a
                >
              </div>
              <div class="mx-2 d-flex align-items-center">
                <i class="fas fa-headset fa-lg mx-2 text-muted"></i>
                <a
                  :href="`tel:+${site_info.contact.call_center}`"
                  dir="ltr"
                  class="d-inline"
                  >{{ site_info.contact.call_center }}</a
                >
              </div>
              <div class="mx-2 d-flex align-items-center">
                <i class="fas fa-envelope-open-text fa-lg mx-2 text-muted"></i>
                <a
                  :href="`mailto:${site_info.contact.email}`"
                  class="d-inline"
                  >{{ site_info.contact.email }}</a
                >
              </div>
              <div class="nav-item dropdown header-search">
                <button
                  aria-label="search"
                  class="nav-link dropdown-toggle border-0 btn"
                  id="searchDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="fa fa-search text-dark" aria-hidden="true"></i>
                </button>
                <div
                  class="dropdown-menu p-0"
                  aria-haspopup="true"
                  aria-labelledby="searchDropdown"
                >
                  <form action="" method="get">
                    <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('label.keywords')"
                      aria-label="search"
                    />
                  </form>
                </div>
                <!--search box-->
              </div>
            </div>
          </div>
          <!--navbar-top-right-->
          <div
            class="
              header-navbar-top-end
              d-flex
              justify-content-between
              align-items-center
              mt-sm-3
            "
          >
            <router-link
              to="/projects"
              class="btn edc-btn-secondary text-white rounded-20 mx-2 fs-7"
            >
              <span class="text-white">{{ $t("btn.donate_now") }}</span>
            </router-link>

            <a
              :href="`tel:+${site_info.contact.agent_mobile}`"
              class="btn btn-outline-secondary mx-2 rounded-20 fs-7"
            >
              {{ $t("btn.call_representative") }}
            </a>
            <router-link
              to="/user-login"
              v-if="!isLogin"
              class="btn rounded-20 fs-7 border-none mx-1 btn-outline-secondary"
            >
              {{ $t("btn.login") }}</router-link
            >
            <router-link
              to="/user-register"
              v-if="!isLogin"
              class="btn edc-color-secondary rounded-20 mx-1 fs-7"
            >
              {{ $t("btn.register") }}</router-link
            >
            <!-- social media links-->
            <social-media :isHeader="true"></social-media>
            <!--//social media links-->
          </div>
          <!--navbar-top-left-->
        </div>
      </div>
      <hr class="dropdown-divider" />
    </div>
    <!-- main navbar-->
    <div class="container">
      <nav-bar></nav-bar>
    </div>
    <!--//main navbar-->
  </header>
</template>
 <script>
import NavBar from "./NavBar";
import SocialMedia from "@/components/SocialMedia";
import auth from "../../auth";
import { mapGetters } from "vuex";
import ViaNajatComponent from "../../components/ViaNajatComponent.vue";
export default {
  name: "HeaderLayout",
  data() {
    return {
      header: "header",
      auth: auth,
    };
  },
  computed: {
    ...mapGetters("auth", ["user", "isLogin"]),
    ...mapGetters("site", ["site_info"]),
  },
  components: {ViaNajatComponent, NavBar, SocialMedia },
};
</script>