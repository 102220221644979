<template>
  <div>
    <footer class="edc-bg-primary py-2">
      <div class="container">
        <div class="row py-3">
          <div class="col-xl-3 col-md-3 col-xs-12 pt-4">
            <router-link to="/" class="navbar-brand mx-0">
              <img
                src="@/assets/images/logo-white.svg"
                width="80"
                height="80"
                :alt="app_name"
                :title="app_name"
                srcset="@/assets/images/logo-white.svg"
              />
              <span class="text-white fw-bold h6">{{ $t("app.name") }}</span>
            </router-link>
            <div class="mt-2">
              <p class="fs-9 text-justify">
لجنة الدعوة الإلكترونية هي لجنة دعوية وتعليمية تابعة لجمعية النجاة الخيرية. تأسست اللجنة عام 2012، لتصبح واحدة من أهم وأكبر المؤسسات الدعوية في العالم الإسلامي. تهدف اللجنة إلى التعريف بالإسلام - تعليم ونشر القرآن الكريم وترجماته وعلومه - تعليم ورعاية المهتدين الجدد - توظيف أحدث الوسائل التكنولوجية في خدمة الدعوة إلى الله
              </p>
            </div>

            <social-media :isHeader="false"></social-media>
            <via-najat-component></via-najat-component>
          </div>
          <div class="col-xl-2 col-md-2 col-xs-12 px-2">
            <h1 class="h6 fw-bold my-4">
              {{ $t("label.supported_payment_methods") }}
            </h1>
            <footer-payment-list></footer-payment-list>
          
          </div>
          <div class="col-xl-3 col-md-3 col-xs-12 px-2">
            <h1 class="h6 fw-bold my-4">
              {{ $t("label.fast_list") }}
            </h1>
            <div class="footer-fast-links">
              <ul>
                <li>
                  <a
                    href="https://chatonfaith.com/"
                    target="_blank"
                    class="fs-7"
                    >الحوار المباشر</a
                  >
                </li>
                <li>
                  <a
                    href="https://sabeeli.academy/"
                    target="_blank"
                    class="fs-7"
                    >أكاديمية سبيلي</a
                  >
                </li>
                <li>
                  <a href="https://quran-uni.com/" class="fs-7" target="_blank"
                    >موقع إذاعات القرآن الكريم</a
                  >
                </li>
                <li>
                  <a
                    href="https://www.muslim-library.com"
                    class="fs-7"
                    target="_blank"
                    >المكتبة الإسلامية الشاملة</a
                  >
                </li>
                <!-- <li>
                  <a
                    href="#"
                    class="fs-7 map edc-color-primary px-2 rounded-pill fw-bold"
                  >
                    {{ $t("label.site_map") }}
                  </a>
                </li> -->
              </ul>
            </div>
          </div>
          <div class="col-xl-4 col-md-4 col-xs-12 px-2">
            <h1 class="h6 fw-bold my-4">
              {{ $t("label.contact_with_us") }}
            </h1>
            <div class="footer-conatct-us">
              <div class="d-flex mb-2 align-items-center">
                <i class="fas fa-map-marker-alt mx-2"></i>
                <span class="fs-7">
                  <label class="edc-color-yellow">{{
                    $t("label.address")
                  }}</label>
                  {{ site_info.contact.address }}
                </span>
              </div>
              <div class="d-flex mb-2 align-items-center">
                <i class="fas fa-phone mx-2"></i>
                <span class="fs-7 align-middle">
                  <label class="edc-color-yellow">{{
                    $t("label.footer_mobile")
                  }}</label
                  >:
                  <a
                    :href="`tel+:${tel}`"
                    v-for="tel in site_info.contact.mobile.split(',')"
                    :key="tel"
                  >
                    {{ tel }} ,
                  </a>
                </span>
              </div>
              <div class="d-flex mb-2 align-items-center">
                <i class="fas fa-phone mx-2 mx-2"></i>
                <span class="fs-7">
                  <label class="edc-color-yellow">{{
                    $t("label.call_center")
                  }}</label
                  >:
                  <a :href="`tel+:${site_info.contact.call_center}`">{{
                    site_info.contact.call_center
                  }}</a>
                </span>
              </div>
              <div class="d-flex mb-2 align-items-center">
                <i class="fas fa-headset mx-2"></i>
                <span class="fs-7">
                  <label class="edc-color-yellow">
                    {{ $t("label.agetnt_number") }} </label
                  >:
                  <a :href="`tel:+${site_info.contact.agent_mobile}`">{{
                    site_info.contact.agent_mobile
                  }}</a>
                </span>
              </div>
              <div class="d-flex mb-2 align-items-center">
                <i class="fas fa-envelope-open-text mx-2"></i>
                <span class="fs-7">
                  <label class="edc-color-yellow">{{ $t("label.email") }}</label
                  >:
                  <a :href="`mailto:${site_info.contact.email}`">{{
                    site_info.contact.email
                  }}</a>
                </span>
              </div>
            </div>
          </div>
        </div>
        <hr class="dropdown-divider" />
        <div class="copyright text-center py-3">
          جميع الحقوق محفوظة لموقع لجنة الدعوة الالكترونية © 2005-{{
            curancy_year
          }}
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import SocialMedia from "@/components/SocialMedia";
import { mapGetters, mapActions } from "vuex";
import FooterPaymentList from "../../components/FooterPaymentList.vue";
import ViaNajatComponent from "../../components/ViaNajatComponent.vue";
export default {
  name: "FooterLayout",
  data() {
    return {
      footer: "footer",
      curancy_year: new Date().getFullYear(),
      app_name: "",
    };
  },
  computed: {
    ...mapGetters("site", ["site_info"]),
  },
  methods: {
    //...mapActions("site", ["getSiteInfo"]),
  },
  created() {
    //this.getSiteInfo();
    this.app_name = this.$t("app.name");
  },
  components: {
    ViaNajatComponent,
    FooterPaymentList,
    SocialMedia,
  },
};
</script>