<template>
  <div>
    <div v-if="isShow">
      <div class="d-flex align-items-center">
        <div class="spinner-border mx-3 text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div :class="[titleClass]">{{ $t("message.loading") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { isShow: Boolean,titleClass:{type:String,default: "h5"}},
};
</script>

<style lang="scss" scoped>
</style>