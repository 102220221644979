<template>
  <div v-if="site_info.via_alnajat">
    <div class="container">
      <div class="mx-auto my-1 d-flex align-items-center justify-content-center gap-3 text-center" >
        <img
            src="@/assets/images/logo/najat_logo.png"
            alt="alnajat"
            data-aos="fade-in"
            width="50"
            height="50"
        />
        <div class="fs-7">لجنة الدعوة الإلكترونية التابعة لجمعية النجاة الخيرية</div>
      </div>
      
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default{
  name: "ViaNajatComponent",
  computed: {
    ...mapGetters("site", ["site_info"]),
  },
}
</script>



<style scoped>

</style>