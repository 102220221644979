
<template>
  <div>
    <div v-if="!isLogin">
      <img
        src="@/assets/images/avatar.png"
        width="40"
        height="40"
        class="rounded-circle d-block mx-auto border border-3 dropdown-toggle"
        type="button"
        id="dropdownUserArea"
        data-bs-toggle="dropdown"
        alt="avatar"
        aria-expanded="false"
      />
      <router-link :to="{ name: 'login' }">
        <span class="edc-color-secondary fs-7">{{ $t("title.login") }}</span>
      </router-link>
    </div>
    <div class="dropdown" v-if="isLogin">
      <img
        v-if="user.user_avatar === ''"
        src="@/assets/images/avatar.png"
        width="40"
        height="40"
        class="rounded-circle d-block mx-auto border border-3 dropdown-toggle"
        type="button"
        id="dropdownUserArea"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        :alt="user.display_name"
      />
      <img
        v-else
        :src="user.user_avatar"
        width="50"
        height="50"
        class="rounded-circle d-block mx-auto border border-3 dropdown-toggle"
        type="button"
        id="dropdownUserArea"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        :alt="user.display_name"
      />
      <div class="d-flex align-items-center justify-content-between">
        <span>{{ $t("label.wellcome") }}</span>
        <span class="edc-color-secondary">{{ user.display_name }}</span>
      </div>

      <ul
        class="dropdown-menu dropdown-menu-start"
        aria-labelledby="dropdownUserArea"
      >
        <li>
          <router-link
            to="/mydonate"
            class="
              dropdown-item
              py-2
              d-flex
              justify-content-start
              align-items-center
            "
          >
            <i class="fas fa-hand-holding-usd fs-3"></i>
            <span class="fs-7 me-3">{{ $t("label.my_donations") }}</span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/wishlist"
            class="
              dropdown-item
              py-2
              d-flex
              justify-content-start
              align-items-center
            "
          >
            <i class="fas fa-check-double fs-3"></i>
            <span class="fs-7 me-3">{{ $t("label.my_following") }}</span>
          </router-link>
        </li>
        <li>
          <hr class="dropdown-divider" />
        </li>
        <li>
          <router-link
            class="
              dropdown-item
              py-2
              d-flex
              justify-content-start
              align-items-center
            "
            :to="{ path: '/profile' }"
          >
            <i class="fas fa-user-circle fs-3"></i>
            <span class="fs-7 me-3">{{ $t("label.profile") }}</span>
          </router-link>
        </li>
        <li>
          <router-link
            class="
              dropdown-item
              py-2
              d-flex
              justify-content-start
              align-items-center
            "
            :to="{ path: '/change-password' }"
          >
            <i class="fas fa-key fs-4"></i>
            <span class="fs-7 me-3">{{ $t("label.change_password") }}</span>
          </router-link>
        </li>
        <li>
          <a
            @click="doLogout()"
            class="
              dropdown-item
              py-2
              d-flex
              justify-content-start
              align-items-center
            "
            href="#"
          >
            <i class="fas fa-sign-out-alt fs-4 text-danger"></i>
            <span class="fs-7 me-3">{{ $t("label.logout") }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
const images = {
  avatar: "images/avatar.png",
};

export default {
  name: "header-user-box",
  data() {
    return {
      images: images,
    };
  },
  computed: {
    ...mapGetters("auth", ["user", "isLogin"]),
  },
  methods: {
    ...mapActions("auth", ["logOut"]),
    doLogout() {
      this.logOut();
      this.$root.$emit("authLogout");
      this.$router.push({ name: "home" });
      //console.log("Logout");
    },
  },
  created() {
    //this.getUser();
  },
  mounted: function () {},
};
</script>