export default {
	authSaveKey: "edc_auth",
	setAUthData(data) {
		localStorage.setItem(this.authSaveKey, JSON.stringify(data));
	},
	getAUthData() {
		return JSON.parse(localStorage.getItem(this.authSaveKey));
	},
	logOut() {
		localStorage.setItem(this.authSaveKey, JSON.stringify({}));
	},
	isLogin() {
		let authData = this.getAUthData();
		if (
			authData !== undefined &&
			authData !== null &&
			authData.is_login !== undefined
		) {
			return authData.is_login;
		}
		return false;
	},
	getApiToken() {
		let authData = this.getAUthData();
		if (
			authData !== undefined &&
			authData !== null &&
			authData.api_token !== undefined
		) {
			return authData.api_token;
		}
		return "";
	},
};
