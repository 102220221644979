<template>
  <div @click.prevent="setNotfiyRead()">
    <div
      :data-count="count < 100 ? count : '99+'"
      class="badget-count btn rounded-20 position-relative"
      id="dropdownNotificationsArea"
    >
      <i
        class="fas fa-bell fs-4 align-middle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      ></i>
      <ul
        class="dropdown-menu dropdown-menu-start rounded-20 pb-3"
        id="dropdownNotificationsBox"
        aria-labelledby="dropdownNotificationsArea"
      >
        <li>
          <div class="notfiy-header"></div>
          <div class="notfiy-body">
            <div v-if="count <= 0" class="alert-info p-2 text-center">
              {{ $t("message.not_notify_found") }}
            </div>
            <ul class="ullist">
              <li v-for="item in list" :key="item.id">
                <div
                  class="
                    p-2
                    border-bottom border-1
                    d-flex
                    justify-content-between
                    align-items-center
                    mx-2
                  "
                >
                  <div
                    :class="`text-white bg-${item.type}`"
                    class="icon-notify ms-2 py-2 px-3 rounded-10"
                  >
                    <i
                      class="fas"
                      :class="{
                        'fa-exclamation-circle': item.type === 'info',
                        'fa-check-circle': item.type === 'success',
                        'fa-exclamation-triangle': item.type === 'waring',
                        'fa-times-circle': item.type === 'danger',
                      }"
                    ></i>
                  </div>

                  <span class="text-end d-fill">
                    <a :href="item.data.url" v-html="item.data.message"></a>
                  </span>
                  <small class="text-muted me-2">{{ item.since }}</small>
                </div>
              </li>
            </ul>
          </div>
          <div class="notfiy-footer">
            <div class="d-grid mx-2 mt-2">
              <router-link
                :to="{ path: '/notifications' }"
                class="btn edc-btn-primary text-white rounded-pill"
              >
                {{ $t("btn.show_all") }}
              </router-link>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
//TODO CHANGE ICONS VIA TYPE
import { mapGetters, mapActions } from "vuex";

export default {
  name: "user-notification",
  data() {
    return {
      isStart: false,
    };
  },
  computed: {
    ...mapGetters("notify", ["count", "list"]),
  },
  methods: {
    ...mapActions("notify", ["getList", "setNotfiyRead","resetNotify"]),
    pushNewCountNotify(_count) {
      this.$dtoast.pop({
        preset: "success",
        color: "white",
        duration: "3500",
        background: "#5743a0",
        progress_thumb_bg: "rgba(32,105,156,.9)",
        heading: this.$t("title.new_ntify_com"),
        content: this.$t("message.new_un_read_notify", {
          notify_count: _count,
        }),
      });
    },
  },
  created() {
    this.getList();
    setInterval(() => {
      this.getList();
    }, 30000);
  },
  updated() {
    this.isStart = true;
  },
    mounted() {
      let _this = this;
      this.$root.$on("authLogin",(payload)=>{
          _this.getList();
      });
        this.$root.$on("authLogout",()=>{
            _this.resetNotify();
        });
    },
    watch: {
    count: {
      handler: function (newValue, oldValue) {
        if (newValue > oldValue && this.isStart === true) {
          let count = newValue - oldValue;
          this.pushNewCountNotify(count);
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="css" scoped>
.ullist {
  list-style: none;
  padding: 0;
  margin: 0;
}
.icon-notify {
  background-color: #ccc;
}
</style>