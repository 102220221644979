import axios from "axios";
export default {
	namespaced: true,
	state: () => ({
		cart: [],
		items: {},
		fullItems: {},
		isShowLoading: true,
	}),
	getters: {
		cart: (state) => {
			return state.cart;
		},
		items: (state) => {
			return state.items;
		},
		fullItems: (state) => {
			return state.fullItems;
		},
		isShowLoading: (state) => {
			return state.isShowLoading;
		},
	},
	mutations: {
		SET_CART(state, payload) {
			let _internal_cart = JSON.parse(localStorage.getItem("edc_cart"));
			if (_internal_cart === null) {
				_internal_cart = [];
			}
			var index = _internal_cart.findIndex((x) => x.id === payload.id);
			if (index === -1) {
				_internal_cart.push({ id: payload.id, amount: payload.amount });
			} else {
				if (payload.action === "add") {
					_internal_cart[index] = { id: payload.id, amount: payload.amount };
				} else if (payload.action === "delete") {
					_internal_cart.splice(index, 1);
				}
			}
			localStorage.setItem("edc_cart", JSON.stringify(_internal_cart));
		},
		SET_ITEMS(state, payload) {
			state.items = payload;
		},
		SET_FullItems(state, payload) {
			state.fullItems = payload;
		},
		SET_IsShowLoading(state, payload) {
			state.isShowLoading = payload;
		},
	},
	actions: {
		async getCart({ commit, state }) {
			await axios
				.post("/cart", {
					cart: localStorage.getItem("edc_cart"),
				})
				.then((response) => {
					commit("SET_ITEMS", response.data);
				})
				.catch(() => {});
		},
		async getFullCart({ commit, state }, payload) {
			await axios
				.post("/full-cart", {
					cart: localStorage.getItem("edc_cart"),
				})
				.then((response) => {
					commit("SET_FullItems", response.data);
				})
				.catch(() => {})
				.finally(() => {
					commit("SET_IsShowLoading", false);
				});
		},
		deleteItem({ commit, dispatch }, payload) {
			payload.action = "delete";
			commit("SET_CART", payload);
			dispatch("getFullCart");
			dispatch("getCart");
		},
		addToCart({ commit, dispatch }, payload) {
			payload.action = "add";
			commit("SET_CART", payload);
			dispatch("getFullCart");
			dispatch("getCart");

			return true;
		},
		emptyCart({ commit }, payload) {
			commit("SET_CART", []);
			commit("SET_ITEMS", {});
			commit("SET_FullItems", {});

			localStorage.removeItem("edc_cart");
			return true;
		},
		updateCart({ commit, dispatch }, payload) {
			payload.action = "add";
			commit("SET_CART", payload);
			dispatch("getFullCart");
			dispatch("getCart");
			return true;
		},
	},
};
