<template>
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
      <!--logo start-->
      <router-link
        to="/"
        class="navbar-brand mx-0 text-center"
        @click.native="hidenMenu"
      >
        <img
          src="@/assets/images/logo.svg"
          width="50"
          height="50"
          :alt="title"
          class="mb-2"
        />
        <span class="edc-color-primary h6 d-block">{{ $t("app.name") }}</span>
      </router-link>
      <button
        class="navbar-toggler border-0"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click.prevent="isMobileClickMenu = false"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <!--//logo end-->
      <!--main menu start-->
      <div
        class="collapse navbar-collapse"
        :class="{ hide: isMobileClickMenu }"
        id="navbarSupportedContent"
      >
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link
              to="/"
              class="nav-link"
              aria-current="page"
              @click.native="hidenMenu"
              >{{ $t("title.home") }}</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              to="/projects"
              @click.native="hidenMenu"
              class="nav-link"
              >{{ $t("title.our_project") }}</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              to="/chat-on-faith"
              class="nav-link"
              @click.native="hidenMenu"
              >{{ $t("projects.chat_on_faith") }}</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              to="/quran-radios"
              class="nav-link"
              @click.native="hidenMenu"
              >{{ $t("projects.quran_radios") }}</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              to="/sabeeli-academy"
              class="nav-link"
              @click.native="hidenMenu"
              >{{ $t("projects.sabeeli_academy") }}</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              to="/edc-waqf"
              class="nav-link"
              @click.native="hidenMenu"
              >{{ $t("projects.edc_waqf") }}</router-link
            >
          </li>
        
            <navbar-servcies></navbar-servcies>
       
          
          <!-- <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ $t("title.media_center") }}
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <router-link to="/news" class="dropdown-item">{{
                  $t("title.news")
                }}</router-link>
              </li>
            </ul>
          </li> -->
          <li class="nav-item">
            <router-link
              to="/reports"
              @click.native="hidenMenu"
              class="nav-link"
              >{{ $t("title.reports") }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/about"
              class="nav-link"
              @click.native="hidenMenu"
              >{{ $t("title.contact_us") }}</router-link
            >
          </li>
        </ul>
        <div
          class="d-grid gap-2 d-flex justify-content-md-end align-items-center"
        >
          <!-- notifications drop down-->

          <user-notificatio></user-notificatio>
          <!--// notifications drop down-->
          <!-- shop-cart-->
          <shop-cart></shop-cart>
          <!--// shop-cart-->
          <!-- user area-->
          <header-user-box></header-user-box>
          <!--//user area-->
        </div>
      </div>
      <!--//main menu end-->
    </div>
  </nav>
</template>
<script>
import ShopCart from "@/components/Cart";
import NavbarServcies from "@/components/NavbarServcies";
import HeaderUserBox from "@/components/HeaderUserBox";
import UserNotificatio from "../../components/UserNotificatio.vue";

const images = {
  visa: "images/visa.png",
  knet: "images/knet.png",
  logo: "images/logo.svg",
};
export default {
  name: "NavBar",
  data() {
    return {
      images: images,
      isMobileClickMenu: false,
      title: this.$t("app.name"),
    };
  },
  components: { HeaderUserBox, ShopCart, UserNotificatio,NavbarServcies },
  methods: {
    hidenMenu() {
      this.isMobileClickMenu = true;
    },
  },
};
</script>
