import axios from "axios";
export default {
	namespaced: true,
	state: () => ({
		commentslist: [],
		isShowLoadMoreButton: true,
		isShowLoading: false,
	}),
	getters: {
		commentslist: (state) => {
			return state.commentslist;
		},
		isShowLoadMoreButton: (state) => {
			return state.isShowLoadMoreButton;
		},
		isShowLoading: (state) => {
			return state.isShowLoading;
		},
	},
	mutations: {
		SET_COMMENTS(state, payload) {
			payload.forEach((element) => {
				state.commentslist.push(element);
			});
		},
		SET_IS_LOAD_MORE(state, payload) {
			state.isShowLoadMoreButton = payload;
		},
		PUSH_ONE_COMMENT(state, payload) {
			state.commentslist.unshift(payload);
		},
		RESTE_COMMENTS(state, payload) {
			state.commentslist = payload;
		},
		SET_IS_SHOW_LOADING(state, payload) {
			state.isShowLoading = payload;
		},
	},
	actions: {
		async sendComment({ commit }, payload) {
			return await axios.post("/add-comment", payload);
		},
		async getComments({ commit }, payload) {
			commit("SET_IS_SHOW_LOADING", true);
			await axios
				.get("/comments", { params: payload })
				.then((response) => {
					if (response.status === 200) {
						//console.log(response.data);
						commit("SET_COMMENTS", response.data);
					} else if (response.status === 400) {
						commit("SET_IS_LOAD_MORE", false);
					}
				})
				.catch((error) => {})
				.finally(() => {
					commit("SET_IS_SHOW_LOADING", false);
				});
		},
		reset_comment({ commit }, payload) {
			commit("RESTE_COMMENTS", []);
		},
	},
};
