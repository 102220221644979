import axios from "axios";
export default {
	namespaced: true,
	state: () => ({
		site_info: { edc_social: {}, contact: { mobile: "" }, payment: {},myfatoorah_payment : [] },
	}),

	getters: {
		site_info: (state) => {
			return state.site_info;
		},
	},
	mutations: {
		SET_SITE_INFO(state, payload) {
			state.site_info = payload;
		},
	},
	actions: {
		async getSiteInfo({ commit }, payload) {
			await axios
				.get("/site-info")
				.then((response) => {
					if (response.status === 200) {
						commit("SET_SITE_INFO", response.data);
					}
				})
				.catch((error) => {});
		},
	},
};
