<template>
  <div class="mx-1">
    <ICountUp
      :delay="delay"
      :endVal="endVal"
      :options="options"
      @ready="onReady"
    /><slot></slot>
  </div>
</template>

<script type="text/babel">
import ICountUp from "vue-countup-v2";
export default {
  name: "CountUp",
  components: {
    ICountUp,
  },

  props: {
    delay: 1000,
    endVal: {
      type: [Number, String],
      required: true,
      default: 0,
    },
    options: {
      useEasing: true,
      useGrouping: true,
      separator: ",",
      decimal: ".",
      prefix: "",
      suffix: "",
    },
  },
  methods: {
    onReady: function (instance, CountUp) {
      const that = this;
      instance.update(that.endVal);
    },
  },
};
</script>

<style scoped>
</style>