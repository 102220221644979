<template>
  <li class="nav-item">
        <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdownOurServcies"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ $t("title.our_servcies") }}
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownOurServcies">
              <li v-for="(item ,index) in services.services" :key="index">
                <router-link :to="{path: '/services/'+item.slug}" class="dropdown-item"  @click.native="CallHidenMenu" >
                  <div class="d-flex gap-2 align-items-center">
                    <img width="50" class="rounded" height="30" :src="item.image.thumbnails" :alt="item.title">
                    <div>{{ item.title }}</div>
                  </div>
               </router-link>
              </li>
            </ul>
  
        </li>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  // camelCase in JavaScript
  name: "NavbarServcies",
  props: [],
  data() {
    return {};
  },
  methods: {
    ...mapActions("services", ["getServices", "getService"]),
    CallHidenMenu(){
      this.$parent.hidenMenu();

    }
  },
  created() {
    this.getServices();
  },
  computed: {
    ...mapGetters("services", ["services"]),
  },
};
</script>
<style scoped>
.shop-cart-project-list{
    max-height: 300px !important;
    overflow: auto;
}
</style>