import axios from "axios";
export default {
	namespaced: true,
	state: () => ({
		posts: [],
		comments: [],
		post: [],
		isShowLoadMoreButton: false,
		isEmptyData: false,
		isShowLoading: true,
	}),

	getters: {
		posts: (state) => {
			return state.posts;
		},
		comments: (state) => {
			return state.comments;
		},
		post: (state) => {
			return state.post;
		},
		isEmptyData: (state) => {
			return state.isEmptyData;
		},
		isShowLoading: (state) => {
			return state.isShowLoading;
		},

		isShowLoadMoreButton: (state) => {
			return state.isShowLoadMoreButton;
		},
	},
	mutations: {
		SET_POSTS(state, payload) {
			payload.forEach((element) => {
				state.posts.push(element);
			});
		},

		REST_POSTS(state, payload) {
			state.posts = payload;
		},
		SET_POST(state, payload) {
			state.post = payload;
		},
		isEmptyData(state, payload) {
			state.isEmptyData = payload;
		},
		isShowLoading(state, payload) {
			state.isShowLoading = payload;
		},
		SET_IS_LOAD_MORE(state, payload) {
			state.isShowLoadMoreButton = payload;
		},
	},
	actions: {
		async getPosts({ commit }, payload) {
			await axios
				.get("/posts", { params: payload })
				.then((response) => {
					if (response.status === 200) {
						commit("SET_POSTS", response.data);
						commit("SET_IS_LOAD_MORE", true);
					} else if (response.status === 400) {
						commit("SET_IS_LOAD_MORE", false);
					}
				})
				.catch((error) => {
					commit("isEmptyData", true);
				})
				.finally(() => {
					commit("isShowLoading", false);
				});
		},
		async getPost({ commit }, payload) {
			return await axios.get("/post", { params: payload });
		},
		resetPosts({ commit }, payload) {
			commit("REST_POSTS", payload);
		},
	},
};
